<script>
export default {
  name: 'PageTitle',

  props: {
    withGoBack: {
      type: Boolean,
      default: false
    },

    goBackPath: {
      type: String,
      default: ''
    },

    title: {
      type: String,
      required: true
    },

    notH1: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    goBack () {
      if (this.goBackPath !== '') {
        this.$router.push(this.localeLocation(this.goBackPath))
      } else {
        this.$router.go(-1)
      }
    }
  }
}
</script>

<template>
  <div :class="$style.title">
    <div v-if="withGoBack" :class="$style.goback" @click="goBack">
      <img :src="require('~/assets/img/icons/angle-left.svg')" :class="$style.goback__icon" alt="angle left" />
    </div>

    <span v-if="notH1">{{ title }}</span>
    <h1 v-else :class="$style.h1">
      {{ title }}
    </h1>
  </div>
</template>

<style lang="scss" module>
.title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 0 24px;
  color: $white;
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
}

.h1 {
  margin: 0;
  color: $white;
  font-size: 22px;
  font-weight: 700;
  line-height: 30px;
}

.goback {
  margin-right: 12px;
  cursor: pointer;

  &__icon {
    width: 24px;
    height: 24px;
  }
}
</style>

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".title_\\+j\\+K4{display:flex;align-items:center;justify-content:flex-start;margin:0 0 24px;color:#fff;font-size:22px;font-weight:700;line-height:30px}.h1_8r\\+v-{margin:0;color:#fff;font-size:22px;font-weight:700;line-height:30px}.goback_Ge7Zv{margin-right:12px;cursor:pointer}.goback__icon_ph22T{width:24px;height:24px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "title_+j+K4",
	"h1": "h1_8r+v-",
	"goback": "goback_Ge7Zv",
	"goback__icon": "goback__icon_ph22T"
};
module.exports = ___CSS_LOADER_EXPORT___;
